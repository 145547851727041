import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';
import Link from 'components/ui/link';

import topUsers from 'img/blog/updates/top_users_art.png';
import topUsersCount from 'img/blog/updates/top_users_count.png';

const Content = () => {
  return (
    <div>
      <p>You can now see which of your users in the chosen segment are most active.</p>
      <p className="mt-3">
        The information about users is visible in a segment on the top of the sessions list.
      </p>
      <ImageModal src={topUsersCount} alt="users" title="New users" />
      <p className="mt-3">
        This number is clickable and it opens a detailed list of ten most active (by the number of
        sessions) users. You can load up to 50 users.
      </p>
      <ImageModal src={topUsers} alt="users" lazy title="Top active users in segment" />
      <p className="mt-3">
        To get most of this list, you should start to identify your users. Using LiveSession
        Tracking API you can provide a user name and email which will appear on this list. More info
        can be found
        <Link href="/help/user-identification-custom-properties/" target="_blank">
          here
        </Link>
        .
      </p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Top users in segment',
  url: '/blog/top-users-in-segment',
  description: 'See who are the most active users in your segment',
  author,
  img: topUsers,
  date: '2019-02-04',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
